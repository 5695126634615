<template>
  <div class="container c-690 mt-1">
    <router-link
      :to="{ name: 'list-select' }"
      style="color: #000"
    >
      <div class="d-flex align-items-center">
        <div class="box-back text-center">
          <i class="fas fa-long-arrow-left" />
        </div>&nbsp;&nbsp;
        <h5 class="text-center font-weight-bolder m-0">
          บัญชีของฉัน
        </h5>
      </div>
    </router-link>

    <div
      class=" mt-1 card"
      style="padding: 5px 10px"
    >
      <div class="d-flex align-items-center">
        <i class="icon fad fa-user-circle fa-3x" /> &nbsp;&nbsp;
        <div>
          <h5 class="font-weight-bolder m-0">
            {{ UserRes.fname }} {{ UserRes.sname }}
          </h5>
          <small class="font-weight-normal">
            {{ UserRes.tel }}
          </small>
        </div>
      </div>
    </div>

    <div class="ac-top p-0">
      <div class="row">
        <div class="col-12">
          <div class="card bg-balance p-1">
            <small class="text-white"><i class="icon2 fad fa-wallet" /> เครดิตคงเหลือ</small>
            <h3 class="text-white">
              ฿ {{
                Commas(this.$store.state.appConfig.UserData.MainWallet)
              }}
            </h3>
          </div>
        </div>
      </div>
    </div>

    <div
      class="card"
      style="padding: 10px 10px"
    >
      <div class="card border box-shadow-none">
        <div class="card-body">
          <div class="row">
            <div class="col-4 p-0">
              <span class="title">บัญชีธนาคาร</span>
            </div>
            <div class="col-8 text-right p-0">
              <span class="bank-icon">
                <span>
                  <div
                    class="media-icon bg__bank-kbank"
                  >
                    <!-- <img
                      :src="`/bankIcon/${UserRes.path_photo}`"
                      alt=""
                      height="15px"
                    > -->
                    <img
                      :src="`/bankIcon/${type === 'truewallet' ? 'wallet.png' :UserRes.path_photo}`"
                      style="width:26px;border-radius:.25rem;padding:7px;"
                      :style="`background-color: ${type === 'truewallet' ? '#ffbb81' : UserRes.bg};`"
                    >
                  </div>
                </span>
                <strong class="font-weight-bolder">{{ UserRes.bankname }}</strong>
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-6 text-muted text-left p-0">
              เลขที่บัญชี
            </div>
            <div class="col-6 text-bold en text-lg text-right p-0">
              <strong class="font-weight-bolder">{{ UserRes.acc_no }}</strong>
            </div>
          </div>
          <div class="row">
            <div class="col-6 text-muted text-left p-0">
              ชื่อบัญชี
            </div>
            <div class="col-6 text-right p-0">
              <strong class="font-weight-bolder">{{ UserRes.fname }} {{ UserRes.sname }}</strong>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <router-link :to="{ name: 'deposit' }">
            <div class="box-ac-items">
              <i class="icon fad fa-arrow-down" />ฝากเครดิต
            </div>
          </router-link>
        </div>

        <div class="col-6">
          <router-link :to="{ name: 'withdraw' }">
            <div class="box-ac-items">
              <i class="icon fad fa-arrow-up" />ถอนเครดิต
            </div>
          </router-link>
        </div>
      </div>

    </div>

    <div>
      <button
        class="btn btn-100 btn-gradient-primary"
        @click="logout"
      >
        <i class="fas fa-sign-out" /> ออกจากระบบ
      </button>
    </div>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import { initialAbility } from '@/libs/acl/config'

export default {
  props: {
    // eslint-disable-next-line
    UserProfile: Object,
  },
  data() {
    return {
      show: true,
      selected: [],
      userData: JSON.parse(localStorage.getItem('userData')),
      UserRes: {
        username: '',
        tel: '',
        fname: '',
        sname: '',
        bank: '',
        acc_no: '',
        trueaccno: '',
        created_at: '',
      },
    }
  },
  async mounted() {
    this.ShowWallet()
    this.ShowUser()
    this.IntervalWallet = setInterval(() => {
      this.ShowWallet()
    }, 5000)
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem('CountLogin')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
    async ShowUser() {
      try {
        const { data: response } = await this.$http.get(
          'https://lineapi.rmtlotto.com/api/user/show',
        )
        if (response && response.success === true) {
          this.type = response.type
          this.UserRes = response.UserData
        }
      } catch (err) {
        console.log(err)
      }
    },
    async ShowWallet() {
      try {
        const { data: response } = await this.$http.get(
          'https://lineapi.rmtlotto.com/api/wallet/show',
        )
        if (response && response.success === true) {
          const Main = response.WalletData.MainWallet
          this.$store.state.appConfig.UserData.MainWallet = Main.balance
        } else {
          this.$store.state.appConfig.UserData.MainWallet = 0
        }
      } catch (err) {
        console.log(err)
      }
    },
    Commas(x) {
      if (Math.round(x) !== x && x > 0) {
        const Num = Number(x)
        const y = Num.toFixed(2)
        return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
  },
}
</script>

<style>
.bg-balance {
  background-image: url(https://www.ltobet.com/assets/img/bg-banner-home.svg);
  background-repeat: repeat;
  background-size: 100% auto;
}
.btn-withdraw {
  height: 36px;
  background-color: #ffffff27;
  color: #FFF !important;
  border: 1px solid #fff;
  font-weight: 700;
  padding: 0 6px;
  border-radius: 2px !important;
}
.btn-depo {
  height: 36px;
  background: #FFF;
  border: none;
  font-weight: 700;
  padding: 0 6px;
  border-radius: 2px !important;
}
.theme-ltobet .book_bank_content .bank-icon .media-icon {
    width: 20px;
    height: 20px;
    display: block;
    float: left;
    border-radius: 4px;
    position: relative;
}
</style>
